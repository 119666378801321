<section class="">
  <div class="bg_image">
    <img src="../../../assets/images/login_page.png" />
  </div>
  <div class="circle"></div>
  <form class="form box_bg_color block" novalidate (ngSubmit)="validate()" [formGroup]="forgotPasswordForm"
    #form="ngForm">
    <h2 class="form__title formGroup">
      <img [src]="Global.companyDetails.companyLogo" loading="lazy" width="100" srcset="" />
    </h2>
    <p class="form__paragraph">Reset Password</p>
    <p class="form__paragraph" style="font-size: 0.9rem">
      Please provide your email address to receive a password reset link via email.
    </p>

    <div class="custom_input_btn">
      <div class="group formGroup">
        <input type="text" name="email" autocomplete="off" [ngClass]="
            email.invalid && (email.dirty || email.touched)
              ? 'form-control'
              : 'form-control'
          " [pTooltip]="
            email.invalid && (email.dirty || email.touched)
              ? 'Enter your user email'
              : ''
          " tooltipPosition="right" tooltipEvent="focus" required="required" formControlName="email" required
          [class.is-invalid]="email.invalid && (email.dirty || email.touched)" class="highlight" /><span
          class="highlight"></span><span class="bar"></span>
        <label>Email address</label>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback text-left">
          <div *ngIf="email.errors?.['required']">
            Please fill out this field.
          </div>
          <div *ngIf="email.errors?.['minlength']">
            This field must have at least 1 character.
          </div>
          <div *ngIf="email.errors?.['maxlength']">
            This field must have at most 250 characters.
          </div>
          <div
            *ngIf="!email.errors?.['required'] && !email.errors?.['minlength'] && !email.errors?.['maxlength'] && email.errors?.['emailValidator']">
            Invalid email format.
          </div>
        </div>
      </div>
    </div>

    <div class="group" style="margin-top: 2rem">
      <div class="flex justify-content-center w-100 my-2">
        <div [hidden]="!btnLoader" class="btn_loader"></div>
      </div>
      <button [hidden]="btnLoader" type="submit" pButton pRipple>
        <span>Reset</span>
      </button>
    </div>
    <div class="form__paragraph" style="margin-top: 2rem">
      Got here by mistake? Return to the<a class="cursor-pointer" routerLink="/login">
        log in </a>page.
    </div>
  </form>
  <div class="circle_bottom"></div>
  <p-toast></p-toast>
</section>