import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataShareService } from '../Services/data-share.service';
import { ErrorLoggingService } from '../Services/error-logging.service';
import { GlobalService } from '../Services/global.service';

const baseUrl = environment.baseUrl;
const baseUrlVrooom = environment.baseUrlVrooom;
const OSRMDockerUrl = environment.OSRMDockerUrl;

@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {
  currantUrl: any = "";
  companyId: string = "";
  authorization: string = "";

  constructor(
    private router: Router,
    private Global: GlobalService,
    public toaster: MessageService,
    public dataShareService: DataShareService,
    private errorLoggingService: ErrorLoggingService
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    this.companyId = (this.Global.companyDetails.companyId && this.Global.companyDetails.companyId != '' ? this.Global.companyDetails.companyId : this.Global.getLocalStorage({ key: 'companyId' }));
    if (!this.companyId) {
      this.companyId = this.Global.getLocalStorage({ key: 'companyId' })
    }

    let nonAuthUrl = [
      'company/details/get',
      'user/registration',
      'user/login',
      'user/edit',
      'user/forgot/password',
      'user/verify/otp',
      'user/change/password',
    ];

    let routeOptimizeUrl = [
      'route/optimize'
    ]
    let getDistanceAndDurationFromOSRM = [
      'get/estimated/duration'
    ]

    let changePassword = [
      'user/change/password',
    ]

    if (changePassword.includes(request.url)) {
      request.body.userId = this.Global.getLocalStorage({ key: 'userId' }) ? this.Global.getLocalStorage({ key: 'userId' }) : this.Global.userDetails.userId
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currantUrl = event.url;
        // console.log('****** route event.url ******', event.url);
      }
    });

    if (!routeOptimizeUrl.includes(request.url) && !getDistanceAndDurationFromOSRM.includes(request.url)) {

      if (nonAuthUrl.includes(request.url)) {

        this.authorization = this.Global.barrierKey;

        // console.log("****** this.currantUrl.includes('dashboard') ******", this.currantUrl.includes('dashboard'));

        if (this.currantUrl.includes('dashboard')) {
          this.Global.generateUUID();
          request.body.userId = this.Global.getLocalStorage({ key: 'userId' }) != undefined ? this.Global.getLocalStorage({ key: 'userId' }) : this.Global.userDetails.userId;
          this.authorization = (this.Global.getLocalStorage({ key: 'JwtToken' }) != undefined ? this.Global.getLocalStorage({ key: 'JwtToken' }) : this.Global.userDetails.JwtToken);
          request = request.clone({
            headers: request.headers.set('authorization', this.authorization),
          });

        }
        else {
          request = request.clone({
            headers: request.headers.set('bearerkey', this.authorization),
          });
          request.body.userId = this.Global.getLocalStorage({ key: 'userId' }) != undefined ? this.Global.getLocalStorage({ key: 'userId' }) : this.Global.userDetails.userId;

        }
      }
      else {

        // console.log("dashboard else ****** this.Global.getLocalStorage({ key: 'userId' })  ******",this.Global.getLocalStorage({ key: 'userId' }) );
        // console.log('****** dashboard else this.Global.userDetails.userId ******',this.Global.userDetails.userId);

        request.body.userId = this.Global.getLocalStorage({ key: 'userId' }) != undefined ? this.Global.getLocalStorage({ key: 'userId' }) : this.Global.userDetails.userId;
        this.Global.generateUUID();
        this.authorization = (this.Global.getLocalStorage({ key: 'JwtToken' }) != undefined ? this.Global.getLocalStorage({ key: 'JwtToken' }) : '');
        request = request.clone({
          headers: request.headers.set('authorization', this.authorization),
        });
      }

      if (this.companyId && request.url != 'company/details/get') {
        request.body.companyId = this.companyId;
      }

      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });

      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Origin', '*'),
      });

      request = request.clone({
        url: baseUrl + request.url,
      });

      request.body.signature = this.Global.UUID;
    }
    else {
      if (getDistanceAndDurationFromOSRM.includes(request.url)) {
        request = request.clone({
          url: OSRMDockerUrl + request.body,
        });
      } else {
        request = request.clone({
          url: baseUrlVrooom,
        });
      }
    }

    return next.handle(request).pipe(map(event => {
      if (event instanceof HttpResponse) {
        if (!event.body.status) {
          if (event.body.statusCode == 500) {
            this.toaster.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Internal Server Error',
            });
          } else if (event.body.statusCode == 403) {
            this.toaster.add({
              severity: 'error',
              summary: 'Error',
              detail: 'The request was unacceptable !',
            });
          } else if (event.body.statusCode == 400) {
            this.toaster.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Required parameter is missing !',
            });
          } else if (event.body.statusCode == 0) {
            this.toaster.add({
              severity: 'error',
              summary: 'Error',
              detail: 'API Error !',
            });
          } else if (event.body.statusCode == 429) {
            this.toaster.add({
              severity: 'error',
              summary: 'Error',
              detail: event.body.message,
            });
          } else if (event.body.statusCode == 1001) {
            this.toaster.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Company inactive',
            });
          } else if (event.body.statusCode == 1002) {
            this.toaster.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Company expired !',
            });
          } else if (event.body.statusCode == 1003) {
            this.toaster.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Company not found !',
            });
          }
          else if (event.body.statusCode == 409) {
            this.toaster.add({
              severity: 'error',
              summary: 'Error',
              detail: "The request could not be completed due to a conflict with the current state of the authorization.",
            });
            this.dataShareService.teamsArrayDataShareService = [];
            this.dataShareService.driversArrayDataShareService = [];
            this.dataShareService.hubsArrayDataShareService = [];
            this.dataShareService.taskArrayDataShareService = [];
            this.dataShareService.adminArrayDataShareService = [];
            this.dataShareService.dispatcherArrayDataShareService = [];
            this.dataShareService.apiKeysArrayDataShareService = [];
            this.dataShareService.WebhookArrayDataShareService = [];
            this.dataShareService.vehicleBrandArrayDataShareService = [];
            this.dataShareService.vehicleModelArrayDataShareService = [];
            this.dataShareService.vehicleArrayDataShareService = [];
            this.dataShareService.carTypeArrayDataShareService = [];
            this.dataShareService.driverShiftArrayDataShareService = [];
            this.dataShareService.hubZoneArrayDataShareService = [];
            this.dataShareService.serviceZoneArrayDataShareService = [];
            this.dataShareService.branchArrayDataShareService = [];
            this.dataShareService.surgeZoneArrayDataShareService = [];
            this.dataShareService.areaZoneArrayDataShareService = [];
            this.dataShareService.planCreatedArrayDataShareService = [];
            this.dataShareService.planCreatedFromTaskArrayDataShareService = [];
            this.Global.removeLocalStorage({ key: 'userId' })
            this.Global.removeLocalStorage({ key: 'type' })
            this.Global.removeLocalStorage({ key: 'isFirstLogin' })
            this.Global.removeLocalStorage({ key: 'parentUser' })
            this.Global.removeLocalStorage({ key: 'userEmailId' });
            this.Global.removeLocalStorage({ key: 'userPassword' });
            this.Global.removeLocalStorage({ key: 'fullName' });
            this.Global.removeLocalStorage({ key: 'phone' });
            this.Global.removeLocalStorage({ key: 'email' });
            this.Global.removeLocalStorage({ key: 'selectedTeams' });
            this.Global.removeLocalStorage({ key: 'selectedTaskStatus' });
            this.Global.removeLocalStorage({ key: 'selectedDriverStatus' });
            this.Global.removeLocalStorage({ key: 'JwtToken' });
            this.router.navigate(['/login']);
          }
        }
      }
      return event;
    }),
      catchError((error: HttpErrorResponse) => {
        this.toaster.add({
          severity: 'error',
          summary: 'Error',
          detail: 'HttpInterceptor catchError !',
        });

        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.log('This is client side error');
          errorMsg = `Error: ${error.error.message}`;
        } else {
          console.log('This is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        console.log(' :::::::: HttpErrorResponse ::::::::', errorMsg);
        if (error.status === 504) {
          // Handle the 504 Gateway Timeout error
          // Display an error message to the user or perform any necessary actions
        }
        // ! --- vishesḣ---- genrate log file ---
        this.errorLoggingService.logError(request.url, request, error);
        return throwError(error);
        // localStorage.removeItem('default')
        // this.toaster.add({
        //   severity: 'error',
        //   summary: 'Error',
        //   detail: 'HttpInterceptor catchError !',
        // });
        // // this.router.navigate(['login']);
        // return throwError(error);
      })
    );
  }
}
