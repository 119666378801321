export const environment = {

  // ! ---------- Development ------------
  // production: false,
  // baseUrl: "https://development.wiyakdelivery.com/wfleet/",
  // baseUrlVrooom: "https://development.wiyakdelivery.com/vroom/",
  // OSRMDockerUrl: "https://development.wiyakdelivery.com/openstreet/route/v1/driving/",
  // apiKey: "AIzaSyDqXmysQZM8pNUVcU7BWmLE2GutPVcO9vc", // wiyaktechsuperstar1@gmail.com 
  // mqtt: {
  //   hostName: "mqtt.wiyak.com",
  //   path: "/mqtt",
  //   protocol: "wss",
  //   clean: true,
  //   connectTimeout: 4000,
  //   clientId: "dispatchermqtt_" + Math.random().toString(16).substr(2, 10),
  //   userName: "wiyakkuwait",
  //   password: "0C46507DF16A0B4B4A25FC7FCC1D47B7wiyakkuwait",
  //   reconnectPeriod: 1000,
  //   keepalive: 65535
  // },

  // ForceApiKey: "ulO66nVd8zfrgfY172J*4iD+fh7K4Q+ZzxBhNiT2ld-JE7f5FvzMe$NeF55Bk35bza6NobTny57wiHF#ISNKBQU!KKwQC%H=yiGVPGtM1NoG*DYMAiIPVL&amp;9v$vO14SK",
  // encryptSecretKey: "40770da6eaeb1d7e442f8b483fb49e088e72dc5113cb69637510553697035930",
  // leafletMapKey: "pk.eyJ1IjoicmFrZXNodGlzIiwiYSI6ImNreHlhZHE0ajR3cXQydnF3MWJ5bmRoNnAifQ.Cy8QK8eNdoUA2_6mKJeslw",


  // ! ---------- Production ------------
  production: false,
  baseUrl: "https://production.wiyak.online/wifleet/",
  baseUrlVrooom: "https://development.wiyakdelivery.com/vroom/",
  OSRMDockerUrl: "https://development.wiyakdelivery.com/openstreet/route/v1/driving/",
  apiKey: "AIzaSyDqXmysQZM8pNUVcU7BWmLE2GutPVcO9vc", // wiyaktechsuperstar1@gmail.com 
  mqtt: {
    hostName: "mqttwifleet.wiyak.online",
    path: "/mqtt",
    protocol: "wss",
    clean: true,
    connectTimeout: 4000,
    clientId: "wifleet_mqtt_" + Math.random().toString(16).substr(2, 10),
    userName: "WiFleet_prod",
    password: "LEJUwuJSMr6BYd1xoAH7Z60KjZImwmKCVhF32e0SNU2DQfYH4IxGLY",
    reconnectPeriod: 1000,
    keepalive: 65535
  },
  ForceApiKey: "ulO66nVd8zfrgfY172J*4iD+fh7K4Q+ZzxBhNiT2ld-JE7f5FvzMe$NeF55Bk35bza6NobTny57wiHF#ISNKBQU!KKwQC%H=yiGVPGtM1NoG*DYMAiIPVL&amp;9v$vO14SK",
  encryptSecretKey: "40770da6eaeb1d7e442f8b483fb49e088e72dc5113cb69637510553697035930",
  leafletMapKey: "pk.eyJ1IjoicmFrZXNodGlzIiwiYSI6ImNreHlhZHE0ajR3cXQydnF3MWJ5bmRoNnAifQ.Cy8QK8eNdoUA2_6mKJeslw",


  imageUrl: "media/img/fetch/",
  CompanySetting: "CompanySetting/",
  cars: "Car/",
  driver: "Driver/",
  DispatcherImg: "Dispatcher/",
  DispatcherCars: "DispatcherCars/",
  dashboardPageSplitterSize: {
    'dashboardMapPane': "65%",
    'dashboardTablePane': "35%",
  },
  taskDelayObject: {
    timerDuration: 60, // in second
    maxDelay: 'background-color: #ff0000; color: #ffffff;',  // red
    minDelay: 'background-color: #ff9c00; color: #ffffff;', // yellow
    aot: 'background-color: #00ff7e  ; color: #ffffff;', // green
    minAssignTime: -15,  // in minutes
  },
  devClipBoard: true,
  driverMarkerIcon: {
    height: 60,
    width: 60,
    labelOrigin: { x: 0, y: -5 },
    anchor: { x: 50, y: 19 }
  },
  routeOptimizeConfig: {
    driverSpeedForRouteOptimize: 42,
    serviceTimeForRouteOptimize: 10,
    seatCapacityForRouteOptimize: 10
  },
  vroomDriverSpeedFactor: 0.42,
  // deliveryProof: "https://development.wiyakdelivery.com/wfleet/" + "media/img/fetch/" + 'Proof/' //dev
  deliveryProof: "https://production.wiyak.online/wifleet/" + "media/img/fetch/" + 'Proof/', //prod,
  teamDriverLimit: 50
}