<router-outlet></router-outlet>
<!-- <div *ngIf="Global.noInternetConnection != 'onInternet'">
  <main class="no_internet_card">
    <article class="card" [ngClass]="{
          onInternetAnimation: Global.noInternetConnection == 'onInternet',
          noInternetAnimation: Global.noInternetConnection == 'noInternet',
          internet: Global.noInternetConnection == 'internet'}">
      <img class="card__img" src="../../../assets/images/no-internet-connection.gif"
        alt="frontend-mentor-website-qrcode">
      <div class="card__content">
        <h1 class="card__title">{{ Global.noInternetConnection == "noInternet" ? "No internet connection!!" : "Internet
          Connected" }}</h1>
        <p class="card__desc">Oops ! Wait for little bit</p>
      </div>
    </article>
  </main>
</div>
<div *ngIf="hasUpdate">
  <p-dialog [closable]="false" [modal]="true" [(visible)]="hasUpdate" [draggable]="false"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}">
    <div class="app_update_model">
      <div class="cookiesContent" id="cookiesPopup">
        <img src="/assets/images/rocket.gif" alt="cookies-img" />
        <h1 class="font-bold text-xl mb-3">Time to Update!</h1>
        <p>We added lots of new features and fix some bugs to make your experience as smooth as possible</p>
        <button class="accept" (click)="reloadSite()">Update !</button>
      </div>
    </div>
  </p-dialog>
</div> -->

<!-- Booking warning alert  audio-->
<audio id="acceptOrderNotification">
  <source src="../assets/music/accept-order.ogg" type="audio/mpeg">
</audio>
<!-- <p-toast></p-toast> -->






<!-- <div class="trail_expire_container_modal">
  <div class="card">
    <div class="header">
      <span class="icon">
        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd"
            d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
            fill-rule="evenodd"></path>
        </svg>
      </span>
      <p class="alert">New message!</p>
    </div>

    <p class="message">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam ea quo unde
      vel adipisci blanditiis voluptates eum. Nam, cum minima?
    </p>

    <div class="actions">
      <a class="read" href="">
        Take a Look
      </a>

      <a class="mark-as-read" href="">
        Mark as Read
      </a>
    </div>
  </div>
</div> -->