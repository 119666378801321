<section class="">
    <div class="bg_image">
        <img src="../../../assets/images/login_page.png" />
    </div>
    <div class="circle"></div>
    <div class="form box_bg_color">
        <h2 class="form__title formGroup"> <img loading="lazy" [src]="Global.companyDetails.companyLogo" width="100"
                alt="" srcset=""> </h2>
        <p class="form__paragraph">Choose Password</p>
        <p class="form__paragraph" style="font-size: 0.9rem;">
            Choose a new password for your account
        </p>
        <mat-stepper [linear]="linear" #stepper *ngIf="!isFirstLogin">
            <mat-step [stepControl]="OTPForm">
                <form appAutofocusValidation (ngSubmit)="validate('OTP')" #form="ngForm" [formGroup]="OTPForm">
                    <div class="custom_input_btn">
                        <div class="group formGroup">
                            <input type="text" pattern="[0-9]+" maxlength="4" minlength="4"
                                (keypress)="Global.numberOnly($event)" required="required" formControlName="otp"
                                required
                                [ngClass]="otp.invalid && (otp.dirty || otp.touched)? 'form-control':'form-control'"
                                [pTooltip]="otp.invalid && (otp.dirty || otp.touched) ? 'Enter OTP' : '' "
                                tooltipPosition="right" tooltipEvent="focus" required="required" required
                                [class.is-invalid]="otp.invalid && (otp.dirty || otp.touched)" />
                            <span class="highlight"></span><span class="bar"></span>
                            <label>Enter OTP</label>
                        </div>
                    </div>

                    <div>
                        <div class="group" style="margin-top: 2rem;">
                            <div class="flex justify-content-center w-100 my-2">
                                <div [hidden]="!btnLoader" class="btn_loader"></div>
                            </div>
                            <button [hidden]="btnLoader" type="submit" pButton pRipple><span>Verified
                                    OTP</span></button>
                        </div>
                    </div>
                </form>
                <div class="custom_bottom_hover float-end mb-3">
                    <button id="otpBtn" (click)="forgotPassword()" [disabled]="resendOtp">Resend OTP <span
                            *ngIf="resendOtp == true">{{ timeLeft }} sec</span></button>
                </div>
            </mat-step>
            <!-- #docregion label -->
            <mat-step [stepControl]="resetPasswordForm">
                <!-- #enddocregion label -->
                <form appAutofocusValidation (ngSubmit)="validate('changePassword')" #form="ngForm"
                    [formGroup]="resetPasswordForm">
                    <div class="custom_input_btn">
                        <div class="group formGroup">
                            <input type="text" required="required"
                                [ngClass]="password.invalid && (password.dirty || password.touched)? 'form-control':'form-control'"
                                [pTooltip]="password.invalid && (password.dirty || password.touched) ? 'Enter Password' : '' "
                                tooltipPosition="right" tooltipEvent="focus"
                                [class.is-invalid]="password.invalid && (password.dirty || password.touched)"
                                formControlName="password" required /><span class="highlight"></span><span
                                class="bar"></span>
                            <label>Password</label>
                            <div *ngIf="password.invalid && (password.dirty || password.touched)"
                                class="invalid-feedback">
                                <div *ngIf="password.errors?.['pattern']">
                                    Please match the requested format.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom_input_btn">
                        <div class="group formGroup">
                            <input type="text" required="required"
                                [ngClass]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)? 'form-control':'form-control'"
                                [pTooltip]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) ? 'Enter confirm password' : '' "
                                tooltipPosition="right" tooltipEvent="focus"
                                [class.is-invalid]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)"
                                formControlName="confirmPassword" required /><span class="highlight"></span><span
                                class="bar"></span>
                            <label>Confirm Password</label>
                            <div *ngIf="confirmPassword.invalid && password.invalid">

                            </div>
                            <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) || confirmPassword.errors?.passwordMatchingValidatior"
                                class="invalid-feedback">
                                <div>
                                    Please match the requested format password.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="group" style="margin-top: 2rem;">
                            <div class="flex justify-content-center w-100 my-2">
                                <div [hidden]="!btnLoader" class="btn_loader"></div>
                            </div>
                            <button [hidden]="btnLoader" type="submit" pButton pRipple><span>Submit</span></button>
                        </div>
                    </div>
                    <div>
                    </div>
                </form>
                <p class="common-Body3 mt-5">Requires at least 8 characters, including two or more uppercase letters,
                    lowercase letters, numbers, or punctuations.</p>
            </mat-step>
        </mat-stepper>

        <ng-container *ngIf="isFirstLogin">
            <form appAutofocusValidation (ngSubmit)="validate('changePassword')" #form="ngForm"
                [formGroup]="resetPasswordForm">
                <div class="custom_input_btn">
                    <div class="group formGroup">
                        <input type="text" required="required"
                            [ngClass]="password.invalid && (password.dirty || password.touched)? 'form-control':'form-control'"
                            [pTooltip]="password.invalid && (password.dirty || password.touched) ? 'Enter Password' : '' "
                            tooltipPosition="right" tooltipEvent="focus"
                            [class.is-invalid]="password.invalid && (password.dirty || password.touched)"
                            formControlName="password" required /><span class="highlight"></span><span
                            class="bar"></span>
                        <label>Password</label>
                        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                            <div *ngIf="password.errors?.['pattern']">
                                Please match the requested format.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custom_input_btn">
                    <div class="group formGroup">
                        <input type="text" required="required"
                            [ngClass]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)? 'form-control':'form-control'"
                            [pTooltip]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) ? 'Enter confirm password' : '' "
                            tooltipPosition="right" tooltipEvent="focus"
                            [class.is-invalid]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)"
                            formControlName="confirmPassword" required /><span class="highlight"></span><span
                            class="bar"></span>
                        <label>Confirm Password</label>
                        <div *ngIf="confirmPassword.invalid && password.invalid">

                        </div>
                        <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) || confirmPassword.errors?.passwordMatchingValidatior"
                            class="invalid-feedback">
                            <div>
                                Please match the requested format password.
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="group" style="margin-top: 2rem;">
                        <div class="flex justify-content-center w-100 my-2">
                            <div [hidden]="!btnLoader" class="btn_loader"></div>
                        </div>
                        <button [hidden]="btnLoader" type="submit" pButton pRipple><span>Submit</span></button>
                    </div>
                </div>
                <div>
                </div>
            </form>
            <p class="common-Body3 mt-5">At least 8 characters, with two or more uppercase letters, lowercase letters,
                numbers, or punctuation</p>
        </ng-container>




        <div class="form__paragraph">
            Got here by mistake? Return to the<a class="cursor-pointer" routerLink="/login"> log in </a>page.
        </div>
    </div>
    <div class="circle_bottom"></div>
</section>
<p-toast></p-toast>